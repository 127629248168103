import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'pbb-link-bank-button',
  templateUrl: './link-bank-button.component.html',
  styleUrls: ['./link-bank-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, RouterLink, MatIconModule, TranslateModule],
})
export class LinkBankButtonComponent {
  @Input() urlPath = 'linkBank';
  @Input() showBackButton = false;
}

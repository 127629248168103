<div class="link-bank-container">
  <div class="link-bank_title-container">
    <button
      mat-icon-button
      *ngIf="showBackButton"
      class="back-btn"
      [attr.aria-label]="'ARIA_LABEL.BACK' | translate"
      id="header_back-btn"
      routerLink=".."
    >
      <mat-icon>arrow_back_outlined</mat-icon>
    </button>
    <h1 class="link-bank_title" id="link-bank_title">{{ 'COMMON.BUTTON.LINK_ANOTHER_BANK' | translate }}</h1>
    <a class="link-bank" id="link-bank_href" [routerLink]="[urlPath]">
      <span>{{ 'COMMON.BUTTON.LINK_ANOTHER_BANK_ADD' | translate }}</span>
      <mat-icon>add_circle_outline</mat-icon>
    </a>
  </div>
  <div class="link-bank_subtitle" id="link-bank_subtitle">
    {{ 'COMMON.BUTTON.LINK_ANOTHER_BANK_SUBTITLE' | translate }}
  </div>
</div>
